<template>
  <div class="formItem">
    <div>
      <fm-generate-form :data="formJson" :value="formData" :readonly="!isEdit" ref="form" v-if="formVisible" :remote="remoteFuncs" @on-change="changeForm"/>
    </div>
    <div style="position:fixed;bottom:20px;width:100%">
      <van-row>
        <van-col :span="24" v-if="isEdit">
          <van-button type="info" size="large" :loading="loading" round @click="dataFormSubmit">完成</van-button>
        </van-col>
        <van-col :span="12" v-if="!isEdit">
          <van-button type="info" size="large" round @click="edit">编辑</van-button>
        </van-col>
        <van-col :span="12" v-if="!isEdit">
          <van-button type="danger" size="large" round @click="deleteHandle">删除</van-button>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import {isNumber, IdentityCodeValid} from '@/utils/validate'
export default {
  data () {
    let that = this
    return {
      id: null,
      pageId: null,
      isEdit: false,
      loading: false,
      formJson: null,
      formData: null,
      readonly: false,
      formVisible: false,
      dataMap: null,
      remoteFuncs: {}
    }
  },
  mounted () {
    let that = this
    this.formJson = JSON.parse(this.$route.query.json)
    this.pageId = this.$route.query.pageId
    this.formData = {}
    this.remoteFuncs = Object.assign({}, {
      getIdNumberTypeList (resolve) {
        that.$http({
          url: that.$http.adornUrl(`/wxapp/sys/dict/virtual`),
          method: 'get',
          params: that.$http.adornParams({
            'code': 'idNumberType'
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            resolve(data.virtualDicts)
          }
        })
      },
      getNationList (resolve) {
        that.$http({
          url: that.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
          method: 'get',
          params: that.$http.adornParams({
            'orgId': that.orgId,
            'code': 'nation'
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            resolve(data.dicts)
          } else {
            this.$message.error(data.msg)
          }
        })
      },
      getNationalityList (resolve) {
        that.$http({
          url: that.$http.adornUrl(`/wxapp/sys/dict/listDictByCode`),
          method: 'get',
          params: that.$http.adornParams({
            'orgId': that.orgId,
            'code': 'nationality'
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            resolve(data.dicts)
          } else {
            this.$message.error(data.msg)
          }
        })
      },
      getRegistryTypeList (resolve) {
        that.$http({
          url: that.$http.adornUrl(`/wxapp/sys/dict/virtual/registryType`),
          method: 'get',
          params: that.$http.adornParams({
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            resolve(data.registryTypes)
          } else {
            this.$message.error(data.msg)
          }
        })
      },
      getDeviceTypeList (resolve) {
        that.$http({
          url: that.$http.adornUrl(`/wxapp/sys/dict/listDictByCode`),
          method: 'get',
          params: that.$http.adornParams({
            'orgId': that.orgId,
            'code': 'deviceType'
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            resolve(data.dicts)
          } else {
            this.$message.error(data.msg)
          }
        })
      },
      getOrgList (resolve) {
        that.$http({
          url: that.$http.adornUrl(`/wxapp/sys/org/subAreaList`),
          method: 'get',
          params: that.$http.adornParams({
            'communityId': that.$orgId
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            // debugger
            resolve(data.subAreaList)
          } else {
            this.$message.error(data.msg)
          }
        })
      },
      getEducationList (resolve) {
        that.$http({
          url: that.$http.adornUrl(`/wxapp/sys/dict/listDictByCode`),
          method: 'get',
          params: that.$http.adornParams({
            'orgId': that.orgId,
            'code': 'education'
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            resolve(data.dicts)
          } else {
            this.$message.error(data.msg)
          }
        })
      },
      getMarriageList (resolve) {
        that.$http({
          url: that.$http.adornUrl(`/wxapp/sys/dict/listDictByCode`),
          method: 'get',
          params: that.$http.adornParams({
            'orgId': that.orgId,
            'code': 'marriage'
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            resolve(data.dicts)
          } else {
            this.$message.error(data.msg)
          }
        })
      }
    })
    this.$nextTick(() => {
      this.id = this.$route.query.id || ''
      if (this.id) {
        this.formVisible = true
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
        this.init()
        this.isEdit = false
      } else {
        this.formVisible = true
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
        this.isEdit = true
      }
    })
  },
  methods: {
    edit () {
      this.isEdit = true
      this.$forceUpdate()
    },
    init () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/customize/page/table/info`),
        method: 'post',
        params: this.$http.adornParams({
          'id': this.id,
          'pageId': this.pageId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          for (var i in data.info.map) {
            if (data.info.map[i]) {
              if (isNumber(data.info.map[i]) && data.info.map[i].length < 11) {
                data.info.map[i] = parseInt(data.info.map[i])
              } else if (!(data.info.map[i] instanceof Object) && data.info.map[i].indexOf('"percent":100') != -1) {
                data.info.map[i] = JSON.parse(data.info.map[i])
              }
            }
          }
          this.formData = data.info.map
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    changeForm (field, value) {
      let that = this
      if (field === 'orgId') {
        this.$set(this.remoteFuncs, 'getBuildingList', function getBuildingList (resolve) {
          that.$http({
            url: that.$http.adornUrl(`/wxapp/building/info/getBySubArea`),
            method: 'post',
            params: that.$http.adornParams({
              'subArea': value
            }),
            data: that.$http.adornData([], false)
          }).then(({data}) => {
            if (data && data.code === 0) {
              if (data.buildings) {
                data.buildings.forEach(function (item, index) {
                  item.value = parseInt(item.value)
                  if (isNumber(item.name)) {
                    item.name = item.name + '幢'
                  }
                })
              }
              resolve(data.buildings)
            }
          })
        })
      }
      if (field === 'buildingId') {
        this.$set(this.remoteFuncs, 'getUnitList', function getUnitList (resolve) {
          that.$http({
            url: that.$http.adornUrl(`/wxapp/building/unit/listByBuildingId`),
            method: 'get',
            params: that.$http.adornParams({
              'buildingId': value
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              data.units.map(item => {
                item.value = parseInt(item.value)
              })
              resolve(data.units)
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
      if (field === 'unitId') {
        this.$set(this.remoteFuncs, 'getHouseList', function getHouseList (resolve) {
          that.$http({
            url: that.$http.adornUrl(`/wxapp/building/house/listByUpId`),
            method: 'get',
            params: that.$http.adornParams({
              'upId': value,
              'bOru': 2
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              data.houseOptions.map(item => {
                item.value = parseInt(item.value)
              })
              resolve(data.houseOptions)
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
    },
    dataFormSubmit () {
      this.$refs.form.getData().then(res => {
        this.loading = true
        this.dataMap = JSON.parse(JSON.stringify(res))
        for (var i in this.dataMap) {
          if (i == 'userForm' || i == 'deviceInfo') {
            if (this.dataMap[i].idNumber && !IdentityCodeValid(this.dataMap[i].idNumber)) {
              this.$message.error('身份证校验失败，请输入正确的身份证')
              return
            }
            for (var j in this.dataMap[i]) {
               if (typeof(this.dataMap[i][j]) == 'object') {
                this.dataMap[i][j] = JSON.stringify(this.dataMap[i][j])
              } else {
                this.dataMap[i][j] = this.dataMap[i][j].toString()
              }
            }
          } else {
            if (typeof(this.dataMap[i]) == 'object') {
              this.dataMap[i] = JSON.stringify(this.dataMap[i])
            } else {
              this.dataMap[i] = this.dataMap[i].toString()
            }
          }
        }
        this.$http({
          url: this.$http.adornUrl('/wxapp/customize/page/table/saveOrUpdate'),
          method: 'post',
          data: this.$http.adornData({
            'id': this.id || undefined,
            'pageId': this.pageId,
            'orgId': this.$orgId,
            'map': this.dataMap
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$toast.success ({
              message: `${this.id?'修改成功':'新增成功'}`,
              duration: 3000,
              onOpened: () => {
                setTimeout(() => {
                  this.$router.go(-1)
                }, 1500)
              }
            })
          } else {
            this.loading = false
            this.$message.error(data.msg)
          }
        })
      })
    },
    deleteHandle () {
      let ids = [this.id]
      this.$dialog.confirm({
        title: '确定删除吗？'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/wxapp/customize/page/table/delete'),
          method: 'post',
          data: this.$http.adornData(ids, false),
          params: this.$http.adornParams({
            pageId: this.pageId
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$toast.success ({
              message: '删除成功',
              duration: 3000,
              onOpened: () => {
                setTimeout(() => {
                  this.$router.go(-1)
                }, 1500)
              }
            })
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    }
  }
}
</script>
<style scoped>
.formItem {
  background-color: #fff;
  padding: 10px 10px 120px 0;

}
.formItem >>> .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #fff;
}
.formItem >>> .el-radio__input.is-disabled+.el-radio__label {
  color: #606266;
}
.formItem >>> .el-radio__input.is-checked+.el-radio__label {
  color: #409EFF;
}
.formItem >>> .el-radio__input.is-checked .el-radio__inner {
  background-color: #409EFF;
}
.formItem >>> .el-radio__input.is-disabled.is-checked .el-radio__inner::after {
  background-color: #fff;
}
.formItem >>>.is-disabled .el-input__inner {
  cursor: auto;
  background-color:white;
  border-color: #DCDFE6;
  color: #606266;
}
.formItem >>>.is-disabled .el-checkbox__label {
  cursor: auto;
  color: #606266;
}
.formItem >>> .el-range-editor.is-disabled {
  background-color:white;
}
.formItem >>> .el-range-editor.is-disabled input {
  cursor: auto;
  background-color:white;
  color: #606266;
}
.formItem >>> .el-textarea.is-disabled .el-textarea__inner {
  cursor: auto;
  background-color:white;
  color: #606266;
}
.formItem >>> input::-webkit-outer-spin-button,
.formItem >>> input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
.formItem >>> input[type='number'] {
  -moz-appearance: textfield !important;
}
</style>
